<template>
  <hb-input
    v-model="model"
    v-bind="binding"
    :type="inputData.type"
    has-suffix
    no-styled-suffix
  >
    <hb-raw-btn tabindex="-1" @click="toggleVisible()">
      <hb-transition class="hb-password__visible-btn" name="fade">
        <nuxt-icon v-if="!isVisible" name="eye" filled />
        <nuxt-icon v-else name="eye-off" filled />
      </hb-transition>
    </hb-raw-btn>
  </hb-input>
</template>

<script lang="ts">
import { copyValue, InputMixin, usePassword } from 'wue'
import { computed, defineComponent } from 'vue'
import HbInput from '~/components/base/form/HbInput.vue'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'
import HbTransition from '~/components/base/utils/HbTransition.vue'

export default defineComponent({
  name: 'HbPassword',
  components: { HbTransition, HbRawBtn, HbInput },
  mixins: [InputMixin],
  setup(props, ctx) {
    const inputCtx = usePassword(props, ctx)
    const binding = computed(() => {
      const result = copyValue(props)
      delete result.modelValue
      return result
    })

    return {
      ...inputCtx,
      binding,
    }
  },
})
</script>

<style lang="scss">
.hb-password {
  &__visible-btn {
    font-size: 22px;
    color: #98a5ab;
  }
}
</style>
